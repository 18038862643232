import React from 'react';
import { Link } from 'gatsby';
import SimplePageSection from '../../../containers/SaasModern/SimplePage';
import Heading from 'reusecore/src/elements/Heading';

const PrivacyPolicy = () => {
  const content = (
    <div>
      <Heading as="h1" content="Privacy Policy" />
      <p>
        We take your privacy very seriously. PrintableCal will never transmit
        your data to VueSoft or anywhere else.
      </p>
      <Heading as="h3" content="What information do we collect?" />
      <p>
        We collect information from you when you register on our site or place
        an order.{' '}
      </p>
      <p>
        When ordering or registering on our site, as appropriate, you may be
        asked to enter your: name, e-mail address, mailing address or credit
        card information. You may, however, visit our site anonymously.
      </p>
      <Heading as="h3" content="Google User Data" />
      <p>
        PrintableCal can be optionally configured to{' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Google-Calendar">
          import your Google Calendar data
        </Link>
        . This is not done automatically. It must be enabled by you. After
        connecting PrintableCal to your Google Calendar data, PrintableCal will
        use that data when you command generation of a calendar, so you can see
        your Google Calendar events and tasks in the output.{' '}
      </p>
      <p>
        PrintableCal does not store or share your Google user data. Your data is
        not transferred to VueSoft or any other organizations. It's only used in
        the generated output that is local to your computer. You can save the
        generated output as an Excel workbook, Word document, or PowerPoint
        slide - and you can share the saved file with other users, but that is
        outside the scope of this privacy policy and PrintableCal has no
        involvement with what you do with the output after it has been generated
        by PrintableCal.
      </p>
      <Heading as="h3" content="What do we use your information for?" />
      <p>
        Any of the information we collect from you may be used in one of the
        following ways:{' '}
      </p>
      <ul>
        <li>
          <strong>To improve our website</strong>
          <p>
            We value your feedback. We continually strive to improve our website
            offerings based on the information and feedback we receive from you.
          </p>
        </li>
        <li>
          <strong>To improve customer service</strong>
          <p>
            We're here to support you. Your information helps us to more
            effectively respond to your customer service requests and support
            needs.
          </p>
        </li>
        <li>
          <strong>To process transactions</strong>
          <p>
            We don't sell your data. Your information, whether public or
            private, will not be sold, exchanged, transferred, or given to any
            other company for any reason whatsoever, without your consent, other
            than for the express purpose of delivering the purchased product or
            service requested.
          </p>
        </li>
        <li>
          <strong>To send occasional email</strong>
          <p>
            We won't spam you. The email address you provide for order
            processing may be used to send you information and updates
            pertaining to your order, in addition to receiving occasional (no
            more than once a year) company news, updates, related product or
            service information.
          </p>
        </li>
      </ul>
      <Heading as="h3" content="How do we protect your information?" />
      <p>
        We implement a variety of security measures to maintain the safety of
        your personal information when you place an order or enter, submit, or
        access your personal information.{' '}
      </p>
      <p>
        We offer the use of a secure server. All supplied sensitive/credit
        information is transmitted via Secure Socket Layer (SSL) technology and
        then encrypted into our payment gateway providers database only to be
        accessible by those authorized with special access rights to such
        systems, and are required to keep the information confidential.
      </p>
      <p>
        After a transaction, your private information (credit cards, social
        security numbers, financials, etc.) will not be stored on our servers.
      </p>
      <Heading as="h3" content="Do we use cookies?" />
      <p>
        We use cookies to ensure you get the best experience on our website.{' '}
        <Link to="/About/Cookie-Policy">Learn more</Link>
      </p>
      <Heading
        as="h3"
        content="Do we disclose any information to outside parties?"
      />
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your
        personally identifiable information. This does not include trusted third
        parties who assist us in operating our website, conducting our business,
        or servicing you, so long as those parties agree to keep this
        information confidential. We may also release your information when we
        believe release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others rights, property, or safety.
      </p>
      <Heading
        as="h3"
        content="California Online Privacy Protection Act Compliance"
      />
      <p>
        Because we value your privacy we have taken the necessary precautions to
        be in compliance with the California Online Privacy Protection Act. We
        therefore will not distribute your personal information to outside
        parties without your consent.
      </p>
      <Heading
        as="h3"
        content="Children's Online Privacy Protection Act Compliance"
      />
      <p>
        We are in compliance with the requirements of COPPA (Children's Online
        Privacy Protection Act), we do not collect any information from anyone
        under 13 years of age. Our website, products and services are all
        directed to people who are at least 13 years old or older.
      </p>
      <Heading as="h3" content="Your Consent" />
      <p>By using our site, you consent to our website's privacy policy.</p>
      <h3>Changes to our Privacy Policy</h3>
      <p>
        If we decide to change our privacy policy, we will post those changes on
        this page.{' '}
      </p>
      <Heading as="h3" content="Contacting Us" />
      <p>
        If there are any questions regarding this privacy policy you may contact
        us using the form below.
      </p>
      <p>
        This policy is powered by Trust Guard{' '}
        <a
          href="http://www.trust-guard.com/PCI-Compliance-s/65.htm"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          PCI compliance
        </a>{' '}
        scans.
      </p>
    </div>
  );

  return (
    <SimplePageSection
      title="Privacy Policy"
      description="We take your privacy very seriously. PrintableCal will never transmit your data to VueSoft or anywhere else."
      keywords="printable calendar, private calendar, calendar privacy, calendar template, Excel calendar, Google Calendar"
      content={content}
    />
  );
};

export default PrivacyPolicy;
